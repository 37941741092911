import React from 'react';
import './i18n/config'
import './@fake-db';
import { Box } from '@mui/material';

import withAppProviders from './withAppProviders';
import AppRoutes from './layouts/AppRoutes';
import MainLayout from './layouts/Main/MainLayout';
import Header from './layouts/Main/Header';
import Footer from './layouts/Main/Footer';

/**
 *
 */
const App = () => {
  return (
    <Box
      data-testid="app"
    >
      <MainLayout
        footer={<Footer />}
        header={<Header data-testid="app-header"/>}
      >
        <AppRoutes />
      </MainLayout>
    </Box>
  );
};

export default withAppProviders(App)()
