import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enCommon from './en/common.json';

import enHome from './en/home.json';

export const defaultNS = 'common';
export const resources = {
  en: {
    common: enCommon,
    home: enHome,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'en',
  ns: ['home', 'common'],
  defaultNS,
  resources,
});

// Change language functionality
// i18n.changeLanguage('zs');
export default i18n;
