import { createTheme } from '@mui/material';
import { jbColors } from './jbColors';
import { palette } from './palette';
import { rem, typography } from './typography';

const sizeSmallPadding = `${rem(2)} ${rem(2)}`;
const sizeMediumPadding = `${rem(8)} ${rem(28)}`;
const sizeLargePadding = `${rem(12)} ${rem(28)}`;

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    // PLACEHOLDER FOR BUTTON VARIANT OVERRIDES, LIKE BJB ANIMATED DASH
    dashed: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    hint: true;
  }
}

export const theme = createTheme({
  palette,
  typography,
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'hint' },
          style: {
            fontSize: rem(10),
            fontFamily: 'VerlagSSmBook',
          }
        }
      ]
    },
    MuiButton: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          letterSpacing: 1,
          borderRadius: 0,
          padding: sizeMediumPadding
        },
        containedPrimary: {
          border: `1px solid ${palette.primary.main}`,
          '&:hover': {
            // backgroundColor: 'transparent',
          },
          '&:disabled': {
            border: `1px solid ${palette.divider}`,
            backgroundColor: palette.action.disabled,
            color: palette.primary.contrastText,
          },
        },
        containedSecondary: {
          border: `1px solid ${palette.secondary.main}`,
          '&:hover': {
            // backgroundColor: 'transparent'
          },
          '&:disabled': {
            border: `1px solid ${palette.divider}`,
            backgroundColor: palette.action.disabled,
            color: palette.primary.contrastText,
          },
        },
        outlinedPrimary: {
          border: `1px solid ${palette.primary.main}`,
          '&:hover': {
            // backgroundColor: palette.primary.light,
          },
        },
        outlinedSecondary: {
          border: `1px solid ${palette.secondary.main}`,
          '&:hover': {
            // backgroundColor: palette.secondary.light,
          },
        },
        containedSizeLarge: {
          padding: sizeLargePadding,
          minWidth: rem(166),
          minHeight: rem(50),
        },
        containedSizeSmall: {
          padding: sizeSmallPadding,
          fontFamily: 'VerlagSsmBook',
          fontSize: rem(12),
        },
        outlinedSizeLarge: {
          padding: sizeLargePadding,
          minWidth: rem(166),
          minHeight: rem(50),
        },
        outlinedSizeSmall: {
          padding: sizeSmallPadding,
          fontFamily: 'VerlagSsmBook',
          fontSize: rem(12),
        },
      },
      variants: [
        {
          props: { variant: 'dashed' },
          style: {
            textTransform: 'none',
            border: `1px dashed ${jbColors.reflexBlue[100]}`,
          },
        },
        {
          props: { variant: 'dashed', color: 'secondary' },
          style: {
            border: `1px dashed ${jbColors.reflexBlue[100]}`,
          },
        },
      ],
    },
    MuiAppBar: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiMenu: {
      defaultProps: {
        autoFocus: false,
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: palette.action.hover,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: 'VerlagSSmBook',
          textTransform: 'none',
          fontSize: rem(14),
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        colorPrimary: {
          color: palette.primary.light,
        },
        colorSecondary: {
          color: palette.secondary.light,
        },
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: jbColors.black[60],
          fontFamily: 'VerlagSSmBook',
          borderBottom: `1px solid ${palette.divider}`,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          color: jbColors.black[50],
          fontFamily: 'VerlagSSmBook',
        },
        displayedRows: {
          color: jbColors.black[50],
          fontFamily: 'VerlagSSmBook',
        },
        input: {
          color: jbColors.black[50],
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontFamily: 'VerlagSSmBook',
          fontSize: rem(14)
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        variant: 'filled',
      },
      styleOverrides: {
        root: {
          fontFamily: 'VerlagSSmBook',
          fontSize: rem(12),
          alignItems: 'center'
        },
        action: {
          display: 'flex',
          alignItems: 'center',
          padding: 0
        },
      },
    },
    MuiChip: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          fontSize: rem(12),
          borderRadius: 0,
          textTransform: 'uppercase',
          borderColor: palette.primary.light,
          height: '26px',
        }
      }
    }
  },
});
