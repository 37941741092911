declare global {
  interface Window {
    REACT_APP_TARGET_ENV?: string;
    REACT_APP_API_URL?: string;
  }
}

export const TARGET_ENV =
  window.REACT_APP_TARGET_ENV ||
  process.env.REACT_APP_TARGET_ENV ||
  'production';

export const API_URL =
  window.REACT_APP_API_URL || process.env.REACT_APP_API_URL;

export const BASENAME = '/';
if (process.env.NODE_ENV === 'production') {
  console.info(`Target env: ${TARGET_ENV}`);
  console.info(`API URL: ${API_URL}`);
}
console.info(`Target env: ${TARGET_ENV}`);
console.info(`API URL: ${API_URL}`);
console.debug('TEST');
