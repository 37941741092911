import { Grid, Link, LinkProps, styled, Typography, Box } from '@mui/material';

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { rem } from './../../theme/typography';
import ResponsiveContainer from '../ResponsiveContainer';

const StyledFooterLink = styled((props: LinkProps) => (
  <Link
    color="inherit"
    target="_blank"
    underline="hover"
    {...props}
  />
))(({ theme }) => ({
  fontFamily: 'inherit',
  fontSize: rem(10),
  margin: `${theme.spacing(1)} ${theme.spacing(5)}`,
  marginLeft: 0,
  textTransform: 'uppercase',
}));

/**
 * Footer Component
 */
const Footer = () => {
  const { t } = useTranslation(['home']);

  const links: Array<{ label: string; href: string }> = useMemo(() => [], []);

  return (
    <Box
      data-testid="footer-content"
      sx={{
        backgroundColor: 'primary.main',
        minHeight: '100px',
        color: 'primary.contrastText',
        fontFamily: 'VerlagSsmBook',
        py: 4,
      }}
    >
      <ResponsiveContainer>
        <Grid
          container
          sx={{ flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Box sx={{ mb: 3 }}>
            {links.slice(0, 3).map(({ label, href }) => (
              <StyledFooterLink
                href={href}
                key={href}
              >
                {label}
              </StyledFooterLink>
            ))}
          </Box>
        </Grid>
        <Box
          sx={{
            mb: 1,
            display: 'flex',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            justifyContent: {
              xs: 'center',
              sm: 'start',
            },
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {links.slice(3, links.length).map(({ label, href }) => (
            <StyledFooterLink
              data-testid={`footer-jb-${label}`}
              href={href}
              key={label}
            >
              {label}
            </StyledFooterLink>
          ))}
          <Typography
            color="primary.light"
            component="span"
            variant="hint"
          >
            {t('home:footer.copyRight')}
          </Typography>
        </Box>
        <Box>
          <Typography
            color="primary.light"
            component="p"
            data-testid="footer-copyright-text"
            variant="caption"
          >
            {t('home:footer.copyRightText')}
          </Typography>
        </Box>
      </ResponsiveContainer>
    </Box>
  );
};

export default React.memo(Footer);
