import {
  AppBar,
  Box,
  Divider,
  Grid,
  Toolbar,
  Typography,
  BoxProps,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ResponsiveContainer from '../ResponsiveContainer';
import { selectUser } from './../../store/user/userSlice';
import { useAppSelector } from './../../store/hooks';
import dayjs from 'dayjs';

/**
 * Header Component to show the header of the application based on the Main Layout
 */
const Header = (props: BoxProps) => {
  const { t } = useTranslation(['common']);
  const user = useAppSelector(selectUser);
  return (
    <ResponsiveContainer {...props}>
      <AppBar
        color="inherit"
        elevation={0}
        position="sticky"
      >
        <Toolbar disableGutters>
          <Grid
            container
            justifyContent="space-between"
          >
            <Grid
              alignItems="center"
              container
              item
              justifyContent="center"
              xs={1}
            >
              <img
                alt={t('common:app')}
                data-testid="header-logo"
                src={`/images/logo.png`}
                width="150px"
              />
            </Grid>
            <Grid
              alignItems="center"
              container
              item
              justifyContent="end"
              xs={4}
            >
              <Box
                alignItems="center"
                display="flex"
              >
                <Box
                  alignItems="center"
                  display="flex"
                >
                  <Box
                    sx={{
                      mr: 1,
                      display: { xs: 'none', sm: 'none', md: 'block' },
                    }}
                  >
                    <Typography
                      align="right"
                      component="p"
                      data-testid="header-username"
                      variant="body2"
                    >
                      {user?.name || '-'}
                    </Typography>
                    <Typography
                      component="p"
                      data-testid="header-lastlogin"
                      variant="hint"
                    >
                      Last login:{' '}
                      {user?.lastLogin
                        ? dayjs(user?.lastLogin).format('HH:mm A, DD/MM/YYYY')
                        : ''}
                    </Typography>
                  </Box>
                </Box>
                <Divider
                  flexItem
                  orientation="vertical"
                  sx={{
                    mx: {
                      xs: 1,
                      sm: 2,
                    },
                  }}
                  variant="middle"
                />
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </ResponsiveContainer>
  );
};

export default React.memo(Header);
