import { TypographyOptions } from '@mui/material/styles/createTypography';
import { palette } from './palette';

export const rem = (pixel: number) => {
  return `${pixel / 16}rem`;
};

export const typography: TypographyOptions = {
  fontFamily: 'VerlagSSmBook, Helvetica, Arial, sans-serif',
  h1: {
    color: palette.text.primary,
    fontSize: rem(50),
    fontWeight: 'lighter',
    lineHeight: 1.4,
    fontFamily: 'VerlagExtraLight',
    textTransform: 'uppercase',
  },
  h2: {
    color: palette.text.primary,
    fontFamily: 'VerlagSSmLight',
    fontSize: rem(48),
    fontWeight: 'normal',
    lineHeight: 1.2,
  },
  h3: {
    color: palette.text.primary,
    fontSize: rem(40),
    lineHeight: 1.2,
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 900,
    fontSize: rem(36),
    lineHeight: 1.2,
  },
  h5: {
    color: palette.text.primary,
    fontFamily: 'VerlagSSmLight',
    fontWeight: 'normal',
    fontSize: rem(28),
    lineHeight: 1.2,
    textTransform: 'uppercase',
  },
  h6: {
    color: palette.text.primary,
    fontFamily: 'VerlagSSmLight',
    fontWeight: 'normal',
    fontSize: rem(28),
    lineHeight: 1.2,
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: rem(22),
    fontWeight: 'normal',
    fontFamily: 'VerlagSSmLight',
    lineHeight: 1.2,
  },
  subtitle2: {
    color: palette.text.primary,
    fontSize: rem(18),
    fontWeight: 'normal',
    fontFamily: 'VerlagSSmLight',
    lineHeight: 1.2,
  },
  body1: {
    color: palette.text.primary,
    fontSize: rem(16),
    fontWeight: 'normal',
    fontFamily: 'VerlagSSmLight',
  },
  body2: {
    color: palette.text.primary,
    fontSize: rem(16),
    fontWeight: 400,
    fontFamily: 'VerlagSSmBold',
  },
  caption: {
    color: palette.text.primary,
    fontSize: rem(12),
    fontFamily: 'VerlagSSmBook',
  },
  overline: {
    color: palette.text.primary,
    fontSize: rem(10),
    fontWeight: 500,
    letterSpacing: '0.33px',
    lineHeight: '13px',
    textTransform: 'uppercase',
  },
  button: {
    textTransform: 'uppercase',
    fontFamily: 'VerlagSSmBlack',
    fontSize: rem(12),
    fontWeight: 400,
    letterSpacing: '1.5px',
    borderRadius: '0px'
  }
};
