/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers } from '@reduxjs/toolkit';

export const ROOT_ACTIONS = {
  logout: 'logout',
};

const createReducer = (asyncReducers?: any) => (state: any, action: any) => {
  let combinedReducer;
  if (asyncReducers) {
    combinedReducer = combineReducers({
      ...asyncReducers,
    });
  } else {
    // Else just combine the static reducers
    combinedReducer = combineReducers({});
  }

  /**
   * Reset the redux store when user logged out
   */
  if (action.type === ROOT_ACTIONS.logout) {
    state = undefined;
  }

  return combinedReducer
    ? combinedReducer(state, action as never)
    : { ...state };
};

createReducer();

export default createReducer;
