import { Box } from '@mui/material';

import { BASENAME } from './../config';
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

/**
 * AppRoutes will load the app routes.
 * @returns
 */
const AppRoutes = () => {
  return (
    <BrowserRouter basename={BASENAME}>
      <Box>
        <Routes>
          {/* default if nothing matches */}
          <Route
            element={
              <Navigate
                replace
                to="/"
              />
            }
            path="*"
          />
        </Routes>
      </Box>
    </BrowserRouter>
  );
};

export default AppRoutes;
