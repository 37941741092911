// Only for development environment and test environment enable the mock server api

const { TARGET_ENV } = require('config');

// Rest will go through actual backend
if (
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === 'test' ||
  TARGET_ENV === 'devcloud'
) {
  require('./db/user');
}
